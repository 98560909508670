import React, { useState, useEffect, useRef } from "react";
import "./RespondentFilter.scss";
import {
  Button,
  Drawer,
  Row,
  Col,
  Divider,
  Select,
  Input,
  Space,
  Modal,
  Tooltip,
  Checkbox,
  Form,
  InputNumber,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  CloseOutlined,
  RightOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import DeleteIcon from "../../assets/icons/delete.svg";
import PlusIcon from "../../assets/icons/plus-icon.svg";
import { TreeSelect } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { setExploreFilterApplyData } from "../../reducer/PEBCommonSlice";
import {
  getExploreFilterDropdownDataAPI,
  saveExploreFilterAPI,
} from "../../services/ExploreAPI";
import { LeftOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import InfoIcon from "../../assets/icons/info.svg";
// import {INDICATION_TREE_STRUCTURE} from "../../constants/IndicationTreeStructure"

const { SHOW_ALL, SHOW_PARENT } = TreeSelect;

const getParentTitle = (key, tree) => {
  let parentTitle;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parentTitle = node.title;
      } else if (getParentTitle(key, node.children)) {
        parentTitle = getParentTitle(key, node.children);
      }
    }
  }
  return parentTitle;
};

const RespondentFilter = (props) => {
  const { Text } = Typography;
  const { confirm } = Modal;
  const containerRef = useRef(null);

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= 100;
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 100;
    }
  };

  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.PEBCommon?.userDetail); // get User details
  const editAccessFlag = useSelector(
    (state) => state.PEBCommon?.hasAccessToReport
  );
  const userId = userInfo.user_id;
  let [deletedComparator, setDeletedComparator] = useState([]);
  const [open, setOpen] = useState(props.visible);
  const [checkComparatorName, setCheckComparatorName] = useState(false);
  const analysisData = useSelector(
    (state) => state.PEBCommon?.findingsReport.analysisData
  );
  const isOpen = useSelector((state) => state.PEBCommon?.findingsReport.isOpen);
  const selectRef = useRef(null);

  const ageGroupsData = { ageGroups: [] };
  const initialAgeGroups = ageGroupsData.ageGroups;
  const [ageGroups, setAgeGroups] = useState([initialAgeGroups,initialAgeGroups,initialAgeGroups,initialAgeGroups,initialAgeGroups]);
  const [ageValidationMessage, setAgeValidationMessage] = useState("");
  const [dropdownOpenState, setDropdownOpenState] = useState([false,false,false,false,false]);
  const [openComparatorIndex, setOpenComparatorIndex] = useState(null);
  const [dropDownFieldDisabled,setDropDownFieldDisabled]=useState({"Criteria 1":true});
  const [stageDisabledFlag, setStageDisabledFlag] = useState({ });
  const [questionId , setQuestionId] = useState("");
  const [questionCategoryFlag , setQuestionCategoryFlag] = useState ("");
  const [questionResponseFlag , setQuestionResponseFlag] = useState ("");
  const [qualifyingQuesId, setQualifyingQuesId] = useState();
  const [otherIndicationFlag , setOtherIndicationFlag] = useState({0:true,1:true,2:true,3:true,4:true});

  const handleCheckboxChange = (id,ind) => {
    setAgeValidationMessage("");
    setAgeGroups((prevAgeGroups) =>
      prevAgeGroups.map((group, index) => 
        index === ind 
          ? group.map((item) => 
              item.id === id 
                ? { ...item, selected: !item.selected } 
                : item
            ) 
          : group
      )
    );
  };

  //Confirmation Popup for Deleting an Analysis
  const showConfirm = () => {
    confirm({
      title: "Reset",
      icon: <ExclamationCircleOutlined />,
      content:
        "Are you sure you want to Reset ALL defined Filter Criteria, including all Comparator population criteria that has been defined?",
      okText: "Reset",
      className: "modal-delete",
      onOk() {
        filterResetHandler();
      },
      onCancel() {},
    });
  };

  const [filterCategoryData, setFilterCategoryData] = useState([
    {
      key: "1",
      comparatorName: "Criteria 1",
      filters: [
        // {
        //   key: "1",
        //   categoryKey: "therapeutic_area",
        //   categoryLabel: "Therapy Area",
        //   span: 24,
        //   openState: false,
        //   selectedValue: [],
        //   options: [],
        //   loading: false,
        //   selectAll: true,
        //   type: "dropdown",
        // },
        {
          key: "1",
          categoryKey: "primary_indication",
          categoryLabel: "Primary Indication",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "tree",
        },
        {
          key: "2",
          categoryKey: "other_indication",
          categoryLabel: "Other Indication",
          isInfoIcon: true,
          infoMessage :"This filter will be accessible only when at least one primary indication has been selected",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "tree",
        },
        {
          key: "3",
          categoryKey: "stage",
          categoryLabel: "Cancer Stage at Diagnosis",
          isInfoIcon: true,
          infoMessage :"This filter will be accessible only when one primary indication for oncology has been selected.",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "dropdown",
        },
        {
          key: "4",
          categoryKey: "geography",
          categoryLabel: "Geography",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "dropdown",
        },
        {
          key: "5",
          categoryKey: "race",
          categoryLabel: "Race",
          isInfoIcon: true,
          infoMessage :"Find additional demographic details in the SDOH & Lifestyle category on the View Insights tab.",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "dropdown",
        },
        {
          key: "6",
          categoryKey: "ethnicity",
          categoryLabel: "Ethnicity",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "dropdown",
        },
        // {
        //   key: "6",
        //   categoryKey: "origin_country",
        //   categoryLabel: "Country of Origin",
        //   span: 24,
        //   openState: false,
        //   selectedValue: [],
        //   options: [],
        //   loading: false,
        //   selectAll: true,
        //   type: "dropdown",
        // },
        {
          key: "7",
          categoryKey: "age",
          categoryLabel: "Age",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "dropdown-custom",
        },
        {
          key: "8",
          categoryKey: "gender",
          categoryLabel: "Sex at Birth",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "dropdown",
        },
        {
          key: "9",
          categoryKey: "survey_year",
          categoryLabel: "Time Period",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "survey-year-tree",
        },
        {
          key: "10",
          categoryKey: "custom_filter_label",
          categoryLabel: "Custom Filters",
          isInfoIcon: true,
          infoMessage :"Selecting category will not filter respondents unless a question is selected and upon selection of a question, the system will consider respondents who have answered that question.",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "custom-label",
        },
        {
          key: "11",
          categoryKey: "category_name",
          categoryLabel: "Insight Category",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "single-select-dropdown",
        },
        {
          key: "12",
          categoryKey: "question_id",
          categoryLabel: "Qualifying Question",
          span: 24,
          openState: false,
          selectedValue: [],
          selectedValueId:"",
          selectedValueType:[],
          options: [],
          loading: false,
          selectAll: true,
          type: "single-select-dropdown",
        },
        {
          key: "13",
          categoryKey: "lov_id",
          categoryLabel: "Qualifying Response",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "tree",
        },
      
      ],
    },
  ]);
  const filterAppliedData = useSelector(
    (state) => state.PEBCommon?.exploreFilterApplyData
  );

  useEffect(() => {
    let category = filterCategoryData[0].filters.find(
      (ctg) => ctg.categoryKey === "primary_indication"
    );
    dropdownToggleHandler(true, category, 0).then((data) => {
      populateFilterData(data);
    });
  }, []);

  const populateFilterData = (treeData) => {
    if (filterAppliedData && filterAppliedData.length) {
      let newState = filterAppliedData.map((data, index) => {
        let otherMappedData = data.other_mapped_data ? data.other_mapped_data : [];
        let primaryMappedData =data.mapped_data ? data.mapped_data : [];
        let surveyMappedData = data.survey_year_mapped_data ? data.survey_year_mapped_data : [];
        // let therapyArea = data.therapeutic_area ? data.therapeutic_area : []; 
        // let indications = data.indication
        //   ? data.indication.map((ind) => "i-" + ind)
        //   : [];
        // let sub_indications = data?.sub_indication ? data?.mapped_data : [];
        // let category = filterCategoryData[0].filters.find(
        //   (ctg) => ctg.categoryKey === "primary_indication"
        // );
        // sub_indications.forEach((value) => {
        //   let ind = getParentTitle(value, category.options);
        //   if (ind) {
        //     indications = indications.filter(
        //       (indication) => indication !== "i-" + ind
        //     );
        //   }
        // });
        return {
          key: index.toString(),
          comparatorName: data.comparator_name,
          filters: [
            // {
            //   key: "1",
            //   categoryKey: "therapeutic_area",
            //   categoryLabel: "Therapy Area",
            //   span: 24,
            //   openState: false,
            //   selectedValue: data.therapeutic_area || [],
            //   options: [],
            //   loading: false,
            //   selectAll: true,
            //   type: "dropdown",
            // },
            {
              key: "1",
              categoryKey: "primary_indication",
              categoryLabel: "Primary Indication",
              span: 24,
              openState: false,
              selectedValue: [...primaryMappedData] || [],
              options: treeData ? [...treeData] : [],
              loading: false,
              selectAll: true,
              type: "tree",
            },
            {
              key: "2",
              categoryKey: "other_indication",
              categoryLabel: "Other Indication",
              isInfoIcon: true,
              infoMessage :"This filter will be accessible only when at least one primary indication has been selected",
              span: 24,
              openState: false,
              selectedValue: [...otherMappedData] ||[],
              options:treeData ? [...treeData] : [],
              loading: false,
              selectAll: true,
              type: "tree",
            },
            {
              key: "3",
              categoryKey: "stage",
              categoryLabel: "Cancer Stage at Diagnosis",
              isInfoIcon: true,
              infoMessage :"This filter will be accessible only when one primary indication for oncology has been selected. ",
              span: 24,
              openState: false,
              selectedValue: data.stage || [],
              options: [],
              loading: false,
              selectAll: true,
              type: "dropdown",
            },
            {
              key: "4",
              categoryKey: "geography",
              categoryLabel: "Geography",
              span: 24,
              openState: false,
              selectedValue: data.geography || [],
              options: [],
              loading: false,
              selectAll: true,
              type: "dropdown",
            },
            {
              key: "5",
              categoryKey: "race",
              categoryLabel: "Race",
              isInfoIcon: true,
              infoMessage :"Find additional demographic details in the SDOH & Lifestyle category on the View Insights tab.",
              span: 24,
              openState: false,
              selectedValue: data.race || [],
              options: [],
              loading: false,
              selectAll: true,
              type: "dropdown",
            },
            {
              key: "6",
              categoryKey: "ethnicity",
              categoryLabel: "Ethnicity",
              span: 24,
              openState: false,
              selectedValue: data.ethnicity || [],
              options: [],
              loading: false,
              selectAll: true,
              type: "dropdown",
            },
            // {
            //   key: "6",
            //   categoryKey: "origin_country",
            //   categoryLabel: "Country of Origin",
            //   span: 24,
            //   openState: false,
            //   selectedValue: data.origin_country || [],
            //   options: [],
            //   loading: false,
            //   selectAll: true,
            //   type: "dropdown",
            // },
            {
              key: "7",
              categoryKey: "age",
              categoryLabel: "Age",
              span: 24,
              openState: false,
              selectedValue: data.age || [],
              options: [],
              loading: false,
              selectAll: true,
              type: "dropdown-custom",
            },
            {
              key: "8",
              categoryKey: "gender",
              categoryLabel: "Sex at Birth",
              span: 24,
              openState: false,
              selectedValue: data.gender || [],
              options: [],
              loading: false,
              selectAll: true,
              type: "dropdown",
            },
            {
              key: "9",
              categoryKey: "survey_year",
              categoryLabel: "Time Period",
              span: 24,
              openState: false,
              selectedValue: [...surveyMappedData] || [],
              options: [],
              loading: false,
              selectAll: true,
              type: "survey-year-tree",
            },
            {
              key: "10",
              categoryKey: "custom_filter_label",
              categoryLabel: "Custom Filters",
              isInfoIcon: true,
              infoMessage :"Selecting category will not filter respondents unless a question is selected and upon selection of a question, the system will consider respondents who have answered that question.",
              span: 24,
              openState: false,
              selectedValue: [],
              options: [],
              loading: false,
              selectAll: true,
              type: "custom-label",
            },
            {
              key: "11",
              categoryKey: "category_name",
              categoryLabel: "Insight Category",
              span: 24,
              openState: false,
              selectedValue: data.category || [],
              options: [],
              loading: false,
              selectAll: true,
              type: "single-select-dropdown",
            },
            {
              key: "12",
              categoryKey: "question_id",
              categoryLabel: "Qualifying Question",
              span: 24,
              openState: false,
              selectedValue: data.ques_alias || [],
              selectedValueId:"",
              selectedValueType:data.ques_type || [],
              options: [],
              loading: false,
              selectAll: true,
              type: "single-select-dropdown",
            },
            {
              key: "13",
              categoryKey: "lov_id",
              categoryLabel: "Qualifying Response",
              span: 24,
              openState: false,
              selectedValue: data.lov_id_mapped_data || [],
              options: data.lov_id_options || [],
              loading: false,
              selectAll: true,
              type: "tree",
            },
            
          ],
        };
      });
      setFilterCategoryData([...newState]);

      // To enable or disable statge filter, just toggle primary_indication DD, 
      // then statge DD will take its right diable state.
      newState.map((_, index) => {
        let temp = _.filters.find((ctg) => ctg.categoryKey === "primary_indication");
        dropdownToggleHandler(false, temp, index);
      });
    }
  };

  const onClose = () => {
    setOpen(false);
    props.onClose();
  };

  //to transform the selected tree data from dropdown for ta,indication,sub-indication
  const transformSelectedData = (data) => {
    const result = {};

    data.forEach((item) => {
      const parts = item.split(/i-|si-/);

      const therapeuticAreaPart = parts.find((part) => part.startsWith('t-'));
      const indicationPart = parts[1];
      const subIndicationPart = parts[2];

      const therapeuticArea = therapeuticAreaPart
        ? therapeuticAreaPart.replace('t-', '')
        : parts[0];
      const indication = indicationPart ? indicationPart.replace('i-', '') : '';
      const subIndication = subIndicationPart
        ? subIndicationPart.replace('si-', '')
        : '';

      if (!result[therapeuticArea]) {
        result[therapeuticArea] = {
          therapeutic_area: therapeuticArea,
          indication_node: [],
        };
      }

      if (indication && !subIndication) {
        result[therapeuticArea].therapeutic_area = '';
        result[therapeuticArea].indication_node.push({
          indication: indication,
          sub_indication: [],
        });
      }

      if (indication && subIndication) {
        result[therapeuticArea].therapeutic_area = '';
        let ind = result[therapeuticArea].indication_node.find(
          (i) => i.indication === indication
        );
        if (!ind) {
          ind = { indication: indication, sub_indication: [] };
          result[therapeuticArea].indication_node.push(ind);
        }
        ind.sub_indication.push(subIndication);
      }
    });

    return Object.values(result).map((ta) => {
      ta.indication_node = ta.indication_node.map((ind) => {
        if (ind.sub_indication.length > 0) {
          return {
            indication: '',
            sub_indication: ind.sub_indication,
          };
        }
        return ind;
      });
      return ta;
    });
  };

  //transform survey year dropdown selected data
  const transformSurveyYear = (selectedData) => {
    let obj = {};
    const transformedData = {
      year_range: [],
      survey_year: [],
    };

    // Transforming the selected data
    selectedData.forEach((item) => {
      // const yearMatch = item.match(/y-(\d{4})/); // Matches items like "y-2024"
      // const rangeMatch = item.match(/r-(H\d) - (\d{4})/); // Matches items like "r-H1 - 2024"

      const yearMatch = item.match(/^(\d{4})$/); // Matches items like "2024"
      const rangeMatch = item.match(/(\d{4}) (H\d) - (\d{4})/);

      if (yearMatch) {
        if (!transformedData.survey_year.includes(yearMatch[1])) {
          transformedData.survey_year.push(yearMatch[1]);
        }
      }
      if (rangeMatch) {
        transformedData.year_range.push(`${rangeMatch[2]} - ${rangeMatch[3]}`);

        if (!transformedData.survey_year.includes(rangeMatch[1])) {
          transformedData.survey_year.push(rangeMatch[1]);
        }
      }
    });

    obj["survey_year"] = transformedData.survey_year;
    obj["year_range"] = transformedData.year_range;
    return obj;
  };

  const transformLovIdData = (data) => {
    const parsedData = data.map(item => {
      const [lovId, rank] = item.split(" ").map(Number);
      return { 
        lovId: lovId ? String(lovId) : null, 
        rank: rank || null     
      };
    });
  
    const uniqueLovIds = [...new Set(parsedData.map(item => item.lovId))].filter(id => id !== null);
    const ranks = parsedData.map(item => item.rank).filter(rank => rank !== null);
  
    return {
      lov_id: uniqueLovIds,
      rank: ranks
    };
  };

  const saveFilterDataHandler = () => {
    saveFilterDataPayload();
  };
  function anyTwoComparatorNamesSame(arr) {
    const comparatorNameSet = new Set();

    for (const obj of arr) {
      if (comparatorNameSet.has(obj.comparatorName)) {
        return true;
      }
      comparatorNameSet.add(obj.comparatorName);
    }
    return false;
  }
  const saveFilterDataPayload = () => {
    const anyTwoComparatorNamesMatch =
      anyTwoComparatorNamesSame(filterCategoryData);
    if (anyTwoComparatorNamesMatch) {
      setCheckComparatorName(true);
      return true;
    } else {
      setCheckComparatorName(false);
    }

    const filters_data = filterCategoryData.map((comparator, index) => {
      let obj = { comparator_name: comparator.comparatorName };
      comparator.filters.forEach((category) => {
        if (category.categoryKey === "primary_indication") {
          obj["primary_indication"] = transformSelectedData(category.selectedValue);
          obj["mapped_data"] = category.selectedValue;
          obj["indication"] = [];
          obj["therapeutic_area"] = [];
          category.selectedValue.forEach((value) => {
            let firstElement = value.split("i-")[0].trim();
            if (firstElement.startsWith("t-")) {
              firstElement = firstElement.substring(2);
            }
            obj["therapeutic_area"].push(firstElement);
          });
          category.selectedValue.forEach((value) => {
            if (value.split("si-").length === 1) {
              let ind = value.split("i-")[1];
              if (ind) {
                obj["indication"].push(ind);
              }
            }
          });
          obj["sub_indication"] = [];
          category.selectedValue.forEach((value) => {
            if (value.split("si-").length > 1) {
              let sub_ind = value.split("si-")[1];
              obj["sub_indication"].push(sub_ind);
              let ind = getParentTitle(value, category.options);
              if (ind) obj["indication"].push(ind);
            }
          });
          obj["therapeutic_area"] = [...new Set(obj["therapeutic_area"])];
          obj["indication"] = [...new Set(obj["indication"])];
        }else if (category.categoryKey === "other_indication") {
          obj["other_indication"] = transformSelectedData(category.selectedValue);
          obj["other_mapped_data"] = category.selectedValue;
        } else if (category.categoryKey === "survey_year") {
          const trandformedValues = transformSurveyYear(category.selectedValue);
          obj["survey_year"] = trandformedValues?.survey_year;
          obj["year_range"] = trandformedValues?.year_range;
          obj["survey_year_mapped_data"] = category.selectedValue
        } else if(category.categoryKey === "lov_id"){
          console.log("category.selectedValue" , category)
          const transformData = transformLovIdData (category.selectedValue);
          obj[category.categoryKey] = category?.selectedValue?.length > 0 ? [transformData] : category.selectedValue;
          obj['lov_id_mapped_data'] = category.selectedValue;
          obj['lov_id_options'] = category.options;
        } else if (category.categoryKey === "category_name"){
          obj[category.categoryKey] = category.selectedValue === undefined ? [] : Array.isArray(category.selectedValue) ?category.selectedValue :[category?.selectedValue];
        }else if (category.categoryKey === "question_id"){
          console.log("category - ques type ---" , category)
          obj["ques_alias"] = category.selectedValue === undefined ? [] : Array.isArray(category.selectedValue) ? category.selectedValue : [category.selectedValue];
          obj['ques_id'] = qualifyingQuesId ? [qualifyingQuesId] : [];
          obj['ques_type'] = (Array.isArray(category.selectedValueType) && category.selectedValueType.every(val => val === null)) 
          || category.selectedValueType === undefined
          ? []
          : Array.isArray(category.selectedValueType)
          ? category.selectedValueType
          : [category.selectedValueType];
        }
        else {
          obj[category.categoryKey] = category.selectedValue;
        }
      });
      obj["primary_flag"] = index === 0 ? true : false;
      return obj;
    });

    let payload = {
      user_id: userId,
      screen_name: "data_coverage",
      comparator_flag: true,
      delete_comparator_names: deletedComparator,
      filters_data,
      display_chart_nm: [],
      analysis_id: localStorage.getItem("analysisId") || "",
    };
   saveFilterData(payload);
  };
  //API call to Save Dropdowns Data
  const saveFilterData = async (payload) => {
    let response = await saveExploreFilterAPI(payload);
    setDeletedComparator([]);
    onClose(); //after save success
    //dispatch(setExploreFilterApplyData(payload.filters_data));
    // onClose(payload);
  };

  const filterResetHandler = () => {
    const newState = filterCategoryData.map((category, index) => {
      category.comparatorName = `Criteria ${index + 1}`;
      category.filters = [
        // {
        //   key: "1",
        //   categoryKey: "therapeutic_area",
        //   categoryLabel: "Therapy Area",
        //   span: 24,
        //   openState: false,
        //   selectedValue: [],
        //   options: [],
        //   loading: false,
        //   selectAll: true,
        //   type: "dropdown",
        // },
        {
          key: "1",
          categoryKey: "primary_indication",
          categoryLabel: "Primary Indication",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "tree",
        },
        {
          key: "2",
          categoryKey: "other_indication",
          categoryLabel: "Other Indication",
          isInfoIcon: true,
          infoMessage :"This filter will be accessible only when at least one primary indication has been selected",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "tree",
        },
        {
          key: "3",
          categoryKey: "stage",
          categoryLabel: "Cancer Stage at Diagnosis",
          isInfoIcon: true,
          infoMessage :"This filter will be accessible only when one primary indication for oncology has been selected.",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "dropdown",
        },
        {
          key: "4",
          categoryKey: "geography",
          categoryLabel: "Geography",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "dropdown",
        },
        {
          key: "5",
          categoryKey: "race",
          categoryLabel: "Race",
          isInfoIcon: true,
          infoMessage :"Find additional demographic details in the SDOH & Lifestyle category on the View Insights tab.",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "dropdown",
        },
        {
          key: "6",
          categoryKey: "ethnicity",
          categoryLabel: "Ethnicity",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "dropdown",
        },
        // {
        //   key: "6",
        //   categoryKey: "origin_country",
        //   categoryLabel: "Country of Origin",
        //   span: 24,
        //   openState: false,
        //   selectedValue: [],
        //   options: [],
        //   loading: false,
        //   selectAll: true,
        //   type: "dropdown",
        // },
        {
          key: "7",
          categoryKey: "age",
          categoryLabel: "Age",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "dropdown-custom",
        },
        {
          key: "8",
          categoryKey: "gender",
          categoryLabel: "Sex at Birth",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "dropdown",
        },
        {
          key: "9",
          categoryKey: "survey_year",
          categoryLabel: "Time Period",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "survey-year-tree",
        },
        {
          key: "10",
          categoryKey: "custom_filter_label",
          categoryLabel: "Custom Filters",
          isInfoIcon: true,
          infoMessage :"Selecting category will not filter respondents unless a question is selected and upon selection of a question, the system will consider respondents who have answered that question.",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "custom-label",
        },
        {
          key: "11",
          categoryKey: "category_name",
          categoryLabel: "Insight Category",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "single-select-dropdown",
        },
        {
          key: "12",
          categoryKey: "question_id",
          categoryLabel: "Qualifying Question",
          span: 24,
          openState: false,
          selectedValue: [],
          selectedValueId:"",
          selectedValueType:[],
          options: [],
          loading: false,
          selectAll: true,
          type: "single-select-dropdown",
        },
        {
          key: "13",
          categoryKey: "lov_id",
          categoryLabel: "Qualifying Response",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "tree",
        },
       
      ];
      return category;
    });

    setFilterCategoryData([...newState]);
    setStageDisabledFlag({ });
    setQuestionCategoryFlag("");
    setQuestionResponseFlag("");
    setQualifyingQuesId("");
    setOtherIndicationFlag({});
  };

  const options = [];
  for (let i = 10; i < 36; i++) {
    const value = i.toString(36) + i;
    options.push({
      label: `${value}`,
      value,
    });
  }
  const [value, setValue] = useState(["a10", "c12", "h17", "j19", "k20"]);
  const selectProps = {
    mode: "multiple",

    style: {
      width: "100%",
    },
    virtual: false,

    // value,
    placeholder: "All",
    maxTagCount: "responsive",
    allowClear: editAccessFlag,
  };

  const singleSelectProps = {
    mode: "single",

    style: {
      width: "100%",
    },
    virtual: false,

    // value,
    placeholder: "All",
    maxTagCount: "responsive",
    allowClear: editAccessFlag,
  };

  const filterALLIndications = (inputArray) =>{
    return inputArray
    .filter(item => item.includes("i-")) 
    .map(item => {
      const iIndex = item.indexOf("i-");
      const valueAfterI = item.substring(iIndex + 2); 
      const siIndex = valueAfterI.indexOf("si-");
      return siIndex !== -1 ? valueAfterI.substring(0, siIndex).trim() : valueAfterI.trim(); // Remove 'si-' portion if it exists
    });
  }

  const dropdownToggleHandler = (event, category, index) => {
    console.log("dropdownToggleHandler +++++++++++", event, category, index);
    if (category.type === "dropdown-custom") {
       setDropdownOpenState((prevState) => 
      prevState.map((item, i) => (i === index ? true : false)));
      setOpenComparatorIndex(index); // Set the open comparator index
    }

    if(category.categoryKey === "primary_indication"){
      if(category.selectedValue.length > 0){
      checkStageFilter(category.selectedValue , index)
     }
     else{
      // checkStageFilter(filterAppliedData[index]?.mapped_data || [], index)
     }
    }

    if(filterAppliedData[index]?.ques_id.length && !qualifyingQuesId ){
      // console.log("111111111111111" , filterCategoryData[index].filters);
      // const quesAlias = category.selectedValue === filterAppliedData[index]?.ques_alias[0] ? filterAppliedData[index]?.ques_alias[0] : "";
      setQualifyingQuesId(filterAppliedData[index]?.ques_id[0]);
      setQuestionResponseFlag(filterAppliedData[index]?.ques_alias[0]);
    }
    
    if(filterAppliedData[index]?.category.length && category.selectedValue !== undefined ){
      console.log("222222222222");
      setQuestionCategoryFlag(filterAppliedData[index]?.category[0]);
    }

    if(filterCategoryData[index]){
      // console.log("undefinedddddddd" , filterCategoryData[index]?.filters)
      if(filterCategoryData[index]?.filters[11]?.selectedValue === undefined){
        setQualifyingQuesId("");
        setQuestionResponseFlag("");
      }
      if(filterCategoryData[index]?.filters[10]?.selectedValue === undefined){
        // console.log("category",filterCategoryData[index]?.filters[10])
        setQuestionCategoryFlag("");
        setQualifyingQuesId("");
        setQuestionResponseFlag("");
      }
    }

    if(category.categoryKey === "question_id" && (category?.selectedValue?.length === 0 || category.selectedValue === undefined)){
      console.log("3333333333333")
      setQuestionResponseFlag("");
    }

    if(category.categoryKey === "category_name" && filterAppliedData[index]?.ques_id.length ){
      const quesAlias = category.selectedValue === filterAppliedData[index]?.category ? filterAppliedData[index]?.ques_alias[0] : "";
      setQuestionResponseFlag(quesAlias);
      setQualifyingQuesId("");

    }

    const qualifyingResponseFilter = category.categoryKey === "question_id";
    if (qualifyingResponseFilter) {
      const quesId = category.options.filter(
        (response) => response.value === category.selectedValue
      );
      setQualifyingQuesId(quesId[0]?.ques_id);
    }
    
    if (event) {
      return getDropdownPayload(category.categoryKey, index);
    }
  };

  const getDropdownPayload = (column, index) => {
    let indication = [];
    let therapeutic_area = [];
    let sub_indication = [];
    let quesCategory = [];
    let quesValue = "";
    let quesId = "";
    let onlyIndicationList =[];
    switch (column) {
      case "therapeutic_area":
        indication = filterCategoryData[index].filters.filter(
          (category) => category.categoryKey === "primary_indication"
        )[0]?.selectedValue;
        sub_indication = filterCategoryData[index].filters.filter(
          (category) => category.categoryKey === "sub_indication"
        )[0]?.selectedValue;
        break;
      case "indication":
        therapeutic_area = filterCategoryData[index].filters.filter(
          (category) => category.categoryKey === "therapeutic_area"
        )[0]?.selectedValue;
        sub_indication = filterCategoryData[index].filters.filter(
          (category) => category.categoryKey === "sub_indication"
        )[0]?.selectedValue;
        break;
      case "sub_indication":
        therapeutic_area = filterCategoryData[index].filters.filter(
          (category) => category.categoryKey === "therapeutic_area"
        )[0]?.selectedValue;
        indication = filterCategoryData[index].filters.filter(
          (category) => category.categoryKey === "primary_indication"
        )[0]?.selectedValue;
        break;
      case "question_id":
        quesCategory = filterCategoryData[index].filters.filter(
          (category) => category.categoryKey === "category_name"
        )[0]?.selectedValue;
        break;
      case "lov_id":
        quesValue = filterCategoryData[index].filters.filter(
          (category) => category.categoryKey === "question_id"
        )[0]?.selectedValue;
         break;        
      case "age":
        break;
      default:
        break;
    }

    indication = filterCategoryData[index].filters.filter(
      (category) => category.categoryKey === "primary_indication"
    )[0]?.selectedValue;

    if(indication){
      onlyIndicationList = filterALLIndications (indication);
    }
    
    const newState = filterCategoryData;
    newState[index].filters = filterCategoryData[index].filters?.map(
      (filter) => {
        if (filter.categoryKey === column) {
          filter.loading = true;
        }
        return filter;
      }
    );

    const quesList = filterCategoryData[index].filters[11];
    let quesListVal = "";
    
    if(quesList?.selectedValueId && quesList?.selectedValueId.length){
      quesListVal = quesList?.selectedValueId ;
    }else if(qualifyingQuesId){
      quesListVal = qualifyingQuesId
    } else {
      quesListVal =filterAppliedData[index]?.ques_id[0] ;
    }

    setQualifyingQuesId(quesListVal);

    setFilterCategoryData([...newState]);
    let payload = {
      user_id: userId,
      search_string: "",
      screen_name: "data_coverage",
      // limit: 100,
      // offset: 0,
      column_name: column,
      indication : onlyIndicationList,
      therapeutic_area,
      sub_indication,
    };

    const agePayload = {
      ...payload,
      comparator_name: newState[index].comparatorName,
    };

    const quesIdPayload = {
      ...payload,
      category_name : Array.isArray(quesCategory) ? quesCategory[0] : quesCategory,
      question_id : "",
    }
    const lovIdPayload = {
      ...payload,
      question_id : quesListVal,
    }

    return column === "age"
      ? getFilterDropdownData(agePayload, index)
      : column === "question_id" 
      ? getFilterDropdownData(quesIdPayload, index)
      : column === "lov_id" 
      ? getFilterDropdownData(lovIdPayload, index)
      : getFilterDropdownData(payload, index);
  };

  //API call for Dropdown Data
  const getFilterDropdownData = async (payload, index) => {
    let response = await getExploreFilterDropdownDataAPI(payload);
    let options;
    if (payload.column_name === "primary_indication" || payload.column_name === "other_indication") {
      // const taOptions = INDICATION_TREE_STRUCTURE ;
      const taOptions = response.data.list_data;
      options = taOptions?.map((data) => {
        return {
          title: data.category,
          value: "t-" + data.category,
          key: "t-" + data.category,
          level:"t1",
          children: data.indications.map((subData) => {
            return {
              title: subData.indication,
              value: data.category + " i-" + subData.indication,
              key: data.category + " i-" + subData.indication,
              children :subData.sub_indications.map((subs=>{
                return {
                  title :subs.sub_indication,
                  value : data.category + " i-" + subData.indication + " si-"+subs.sub_indication,
                  key :data.category + " i-" + subData.indication + " si-"+subs.sub_indication,
                }
              }))
            };
          }),
        };
      });
      // options = INDICATION_TREE_STRUCTURE
    }else if (payload.column_name === "survey_year" ) {
      const surveyYears = response.data.list_data;
      options = surveyYears?.map((data) => {
        return {
          title: data.value,
          value: data.value,
          key: data.value,
          level:"t1",
          children: data.year_range.map((subData) => {
            return {
              title: subData.value,
              value: data.value +" "+  subData.value,
              key: data.value +" " + subData.value,
            };
          }),
        };
      });
    } else if (payload.column_name === "lov_id" ) {
      const lovIdList = response.data.list_data;
      options = lovIdList?.map((data) => {
        return {
          title: data.lov_text,
          value: data.lov_id,
          key: data.lov_id,
          level:"t1",
          children: data?.lov_rank.map((subData) => {
            return {
              title: "Rank "+subData.rank,
              value: data.lov_id +" "+  subData.rank,
              key: data.lov_id +" " + subData.rank,
            };
          }),
        };
      });
    } 
    else if (payload.column_name === "age") {
      setAgeGroups((prevAgeGroups) => {
        // Check if the ageGroups at the specified index exists
        if (prevAgeGroups[index] && prevAgeGroups[index].length > 0) {
          return prevAgeGroups;
        }
  
        return prevAgeGroups.map((ageGroup, i) =>
          i === index
            ? [
                ...response.data.list_data,
                ...[
                  {
                    id: "",
                    name: "",
                    range: {
                      min: null,
                      max: null,
                    },
                    selected: false,
                  },
                ],
              ]
            : ageGroup
        );
      });
    } else if(payload.column_name === "question_id"){
      options = response.data.list_data?.map((data) => {
        return {
          label: data.value,
          value: data.value,
          disabled: !editAccessFlag,
          ques_id : data.ques_id,
          ques_type: data.ques_type
        };
      });
    } else {
      options = response.data.list_data?.map((data) => {
        return {
          label: data.value,
          value: data.value,
          disabled: !editAccessFlag,
        };
      });
    }
    const newState = filterCategoryData;
    newState[index].filters = filterCategoryData[index].filters?.map(
      (filter) => {
        if (filter.categoryKey === payload.column_name) {
          filter.options = options;
          filter.loading = false;
        }
        return filter;
      }
    );

    setFilterCategoryData([...newState]);
    return options;
  };

  useEffect(() => {
  }, [ageGroups]);

const updateDropDownFieldDisabled =(comparatorName ,disable)=>{
  setDropDownFieldDisabled((prevData)=>(
    {
      ...prevData,
      [comparatorName]:disable 
    }
  ));
}

  const dropdownChangeHandler = (newValue, category, index, comparatorName) => {
    console.log("dropdownChangeHandler -------", newValue, category, index, comparatorName)
    if (category.categoryLabel.toLowerCase() === "primary indication") {
      updateDropDownFieldDisabled(comparatorName, newValue.length === 0);
      checkStageFilter(newValue,index)
    }
    if(category.categoryKey === "category_name"){
      setQuestionCategoryFlag(newValue);
      setQuestionResponseFlag("");
      // setQualifyingQuesId(null);
    }
    if(category.categoryKey === "question_id"){
      setQuestionResponseFlag(newValue);
    }

    // Check for "lov_id" type to enforce single parent selection rule
    if (category.categoryKey === "lov_id") {
      const currentParent = newValue.find(value => !value.includes(" ")); // parent has no space in value
      const selectedChildren = newValue.filter(value => value.includes(" ")); // child nodes contain space in value

      // Check if all selected child nodes are under the current parent
      const isConsistentSelection = selectedChildren.every(value => value.startsWith(currentParent));

      if (!isConsistentSelection) {
        const latestParent = selectedChildren[selectedChildren.length - 1].split(" ")[0]; // parent of the latest selected child
        newValue = newValue.filter(value => value.startsWith(latestParent) || value === latestParent);
      }
    }
    
    const updatedState = filterCategoryData.map((comparator) => {
      return {
        ...comparator,
        filters: comparator.filters.map((filter) => {
          if (category.categoryKey === "category_name" && filter.categoryKey === "category_name") {
            filter.selectedValue = newValue;
            setQuestionCategoryFlag(newValue);
            if(!newValue){
              setQuestionCategoryFlag("");
              setQuestionResponseFlag("");
              setQualifyingQuesId("");
            } 
            // Clear question_id and lov_id whenever category_name changes
            const questionFilter = comparator.filters.find(f => f.categoryKey === "question_id");
            const responseFilter = comparator.filters.find(f => f.categoryKey === "lov_id");
            if (questionFilter){
              // setQuestionCategoryFlag("");
              setQuestionResponseFlag("");
              questionFilter.selectedValue = [];
            } 
            if (responseFilter) responseFilter.selectedValue = [];
          } else  if (category.categoryKey === "question_id" && filter.categoryKey === "question_id") {
            filter.selectedValue = newValue;
            setQuestionResponseFlag(newValue);
            if(newValue === undefined) setQuestionResponseFlag("");
            const responseFilter = comparator.filters.find(f => f.categoryKey === "lov_id");
            if (responseFilter) responseFilter.selectedValue = [];
            const qualifyingResponseFilter = category.categoryKey === "question_id";
            if (qualifyingResponseFilter) {
              const quesId = category.options.filter(
                (response) => response.value === newValue
              );
              setQualifyingQuesId(quesId[0]?.ques_id);
              filter.selectedValueId = quesId[0]?.ques_id;
              filter.selectedValueType = quesId[0]?.ques_type;
            }

          } else if (filter.categoryKey === category.categoryKey && comparator.comparatorName === comparatorName && Number(comparator.key) === index) {
            // Update the specific dropdown only within the current comparator
            filter.selectedValue = newValue;
          }

          
          return filter;
        }),
      };
    });
    
    const newState = [...filterCategoryData];
    newState[index].filters = newState[index].filters.map((filter) => {
      if (filter.categoryKey === category.categoryKey) {
        filter.selectedValue = newValue;
      }
      return filter;
    });
  
    setFilterCategoryData(updatedState);
  };

  const checkStageFilter = (newValue, index) => {

    filterCategoryData[index]?.filters.map((category) => {
      if (category.categoryKey === "stage") {
        category.selectedValue = [];
      }
    });

  // Update `setOtherIndicationFlag` for the current index
  setOtherIndicationFlag((prev) => ({
    ...prev,
    [index]: newValue.length === 0, // Set true if newValue is empty, else false
  }));

    const indications = newValue
      .map((item) => {
        if (item.includes("Oncology")) {
          const indicationMatch = item.match(/i-[^\s]+/);
          return indicationMatch ? indicationMatch[0] : null;
        }
      })
      .filter(Boolean);

    // Get unique indications
    const uniqueIndications = [...new Set(indications)];
console.log("indicationsssss------",uniqueIndications , newValue)
    // const flag = uniqueIndications.length === 1;
    const hasOnlyOncology = newValue.every(item => item.includes("Oncology"));
    const hasSingleOncology = uniqueIndications.length === 1 && hasOnlyOncology;
  
    const flag = hasSingleOncology;
    setStageDisabledFlag(prev => {
      return {
        ...prev,
        [index]: flag
      }
    });
  };

  const [treeValue, setTreeValue] = useState([
    "Child Node3",
    "Child Node4",
    "Child Node5",
  ]);
  const onChange = (newValue) => {
    setTreeValue(newValue);
  };
  const tProps = {
    placeholder: "All",
    maxTagCount: "responsive",
    allowClear: editAccessFlag,
    treeCheckable: true,
    virtual: false,
    showCheckedStrategy: SHOW_PARENT,
    style: {
      width: "100%",
    },
  };

  const tPropsSurveyYear = {
    placeholder: "All",
    maxTagCount: "responsive",
    allowClear: editAccessFlag,
    treeCheckable: true,
    virtual: false,
    showCheckedStrategy: SHOW_ALL,
    style: {
      width: "100%",
    },
  };


  const addComparator = () => {
    setDropdownOpenState((prevState) => 
      prevState.map((item, i) => (i === filterCategoryData.length-1 ? false : false))); //closing age dropdown when we add new comparator
  
    // ADD LOGIC TO PREVENT DUPLICATE NAMES
    const newState = filterCategoryData;
    const comparatorName="Criteria " + (filterCategoryData.length + 1);

      // Get the last comparator to auto-populate certain fields
  const lastComparator = filterCategoryData[filterCategoryData.length - 1] || {};
  const lastSelectedValues = {
    category: lastComparator.filters?.find(f => f.categoryKey === "category_name")?.selectedValue || [],
    question: lastComparator.filters?.find(f => f.categoryKey === "question_id")?.selectedValue || [],
    questionId: lastComparator.filters?.find(f => f.categoryKey === "question_id")?.selectedValueId || [],
    questionListOptions: lastComparator.filters?.find(f => f.categoryKey === "question_id")?.options || [],
    questionType: lastComparator.filters?.find(f => f.categoryKey === "question_id")?.selectedValueType || [],
  };

    newState.push({
      key: (filterCategoryData.length + 1).toString(),
      comparatorName: comparatorName,
      filters: [
        // {
        //   key: "1",
        //   categoryKey: "therapeutic_area",
        //   categoryLabel: "Therapy Area",
        //   span: 24,
        //   openState: false,
        //   selectedValue: [],
        //   options: [],
        //   loading: false,
        //   selectAll: true,
        //   type: "dropdown",
        // },
        {
          key: "1",
          categoryKey: "primary_indication",
          categoryLabel: "Primary Indication",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "tree",
        },
        {
          key: "2",
          categoryKey: "other_indication",
          categoryLabel: "Other Indication",
          isInfoIcon: true,
          infoMessage :"This filter will be accessible only when at least one primary indication has been selected",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "tree",
        },
        {
          key: "3",
          categoryKey: "stage",
          categoryLabel: "Cancer Stage at Diagnosis",
          isInfoIcon: true,
          infoMessage :"This filter will be accessible only when one primary indication for oncology has been selected.",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "dropdown",
        },
        {
          key: "4",
          categoryKey: "geography",
          categoryLabel: "Geography",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "dropdown",
        },
        {
          key: "5",
          categoryKey: "race",
          categoryLabel: "Race",
          isInfoIcon: true,
          infoMessage :"Find additional demographic details in the SDOH & Lifestyle category on the View Insights tab.",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "dropdown",
        },
        {
          key: "6",
          categoryKey: "ethnicity",
          categoryLabel: "Ethnicity",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "dropdown",
        },
        // {
        //   key: "6",
        //   categoryKey: "origin_country",
        //   categoryLabel: "Country of Origin",
        //   span: 24,
        //   openState: false,
        //   selectedValue: [],
        //   options: [],
        //   loading: false,
        //   selectAll: true,
        //   type: "dropdown",
        // },
        {
          key: "7",
          categoryKey: "age",
          categoryLabel: "Age",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "dropdown-custom",
        },
        {
          key: "8",
          categoryKey: "gender",
          categoryLabel: "Sex at Birth",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "dropdown",
        },
        {
          key: "9",
          categoryKey: "survey_year",
          categoryLabel: "Time Period",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "survey-year-tree",
        },
        {
          key: "10",
          categoryKey: "custom_filter_label",
          categoryLabel: "Custom Filters",
          isInfoIcon: true,
          infoMessage :"Selecting category will not filter respondents unless a question is selected and upon selection of a question, the system will consider respondents who have answered that question.",
          span: 24,
          openState: false,
          selectedValue: [],
          options: [],
          loading: false,
          selectAll: true,
          type: "custom-label",
        },
        {
          key: "11",
          categoryKey: "category_name",
          categoryLabel: "Insight Category",
          span: 24,
          openState: false,
          selectedValue: lastSelectedValues.category || [],
          options: [],
          loading: false,
          selectAll: true,
          type: "single-select-dropdown",
        },
        {
          key: "12",
          categoryKey: "question_id",
          categoryLabel: "Qualifying Question",
          span: 24,
          openState: false,
          selectedValue: lastSelectedValues.question || [],
          options: lastSelectedValues.questionListOptions || [],
          selectedValueId:lastSelectedValues.questionId || "",
          selectedValueType: lastSelectedValues.questionType || [],
          loading: false,
          selectAll: true,
          type: "single-select-dropdown",
        },
        {
          key: "13",
          categoryKey: "lov_id",
          categoryLabel: "Qualifying Response",
          span: 24,
          openState: false,
          selectedValue:lastSelectedValues.lov || [],
          options: [],
          loading: false,
          selectAll: true,
          type: "tree",
        },
       
      ],
    });

    setFilterCategoryData([...newState]);
    updateDropDownFieldDisabled(comparatorName,true);
  };

  const deleteCriteria = (index, comparatorName) => {
    setDeletedComparator([...deletedComparator, comparatorName]);
    const newState = filterCategoryData.filter((_, i) => index !== i);

    setFilterCategoryData([...newState]);
  };

  const comparatorRenameHandler = (event, index) => {
    const newState = filterCategoryData.map((category, i) => {
      if (i === index) category.comparatorName = event.target.value;
      return category;
    });

    setFilterCategoryData([...newState]);
  };

  const treeDataWithDisabled = (items) =>
    items.map((item) => ({
      ...item,
      disabled: true,
      children:
        item.children &&
        item.children.map((child) => ({ ...child, disabled: true })),
    }));

    const checkAgeRangeValidations = (ageArrayList) => {

      // Check if more than 5 checkboxes are selected
      if (ageArrayList.length > 5) {
        setAgeValidationMessage("You can select a maximum of 5 age ranges.");
        return true;
      }

      // Helper function to check if age ranges overlap
      const isOverlap = (rangeA, rangeB) => {
        return !(rangeA[1] < rangeB[0] || rangeB[1] < rangeA[0]);
      };

      // Ensure both min and max ages are present and valid
      for (const age of ageArrayList) {
        const [minAge, maxAge] = age.age_value;

        if (
          !(
            age.age_value &&
            Array.isArray(age.age_value) &&
            age.age_value.length === 2
          ) ||
          isNaN(minAge) ||
          isNaN(maxAge) ||
          !Number.isInteger(minAge) ||
          !Number.isInteger(maxAge)
        ) {
          setAgeValidationMessage(
            "Each age entry must have both min and max age values as integers."
          );
          return true;
        } else if (minAge >= maxAge) {
          setAgeValidationMessage(
            "Please enter correct age range values for max and min age. Max age entered is less than Min age."
          );
          return true;
        }
      }

      // Check for duplicate labels
      const labelSet = new Set();
      for (const age of ageArrayList) {
        if (
          !age.age_label ||
          typeof age.age_label !== "string" ||
          age.age_label.trim() === ""
        ) {
          setAgeValidationMessage(
            "Age Range Label cannot be empty . Please add age range label."
          );
          return true;
        }
        if (!labelSet.has(age.age_label)) {
          labelSet.add(age.age_label);
          // return false;
        } else {
          setAgeValidationMessage(
            "Age Range Labels entered are not unique. Please enter unique age range labels."
          );
          return true;
        }
      }

      // Check for overlapping age ranges
      for (let i = 0; i < ageArrayList.length; i++) {
        for (let j = i + 1; j < ageArrayList.length; j++) {
          if (isOverlap(ageArrayList[i].age_value, ageArrayList[j].age_value)) {
            setAgeValidationMessage(
              "Age range entered are overlapping. Please enter distinct age ranges."
            );
            return true;
          } else {
            // return false;
          }
        }
      }
      return false;
    };

  const onFinish = (values, index , name) => {

    const ageArray = [];
    ageGroups[index].slice(0, 4).forEach((item) => {
      if (item.selected) {
        ageArray.push({
          age_label: item.name,
          age_value: [item.range.min, item.range.max],
        });
      }
    });
    values[`ageRanges${index}`].forEach((item) => {
      if (item.checked) {
        ageArray.push({
          age_label: item.name,
          age_value: [item.minAge, item.maxAge],
        });
      }
    });
    const validationFlag = checkAgeRangeValidations(ageArray);
    const newState = filterCategoryData;
    if(!validationFlag){
      newState[index].filters = filterCategoryData[index]?.filters?.map(
        (filter) => {
          if (filter.categoryKey === "age") {
            filter.selectedValue = ageArray;
          }
          return filter;
        }
      );
      setFilterCategoryData([...newState]);
    }else{
      setFilterCategoryData([...newState]);
    }
    if(!validationFlag){
      setDropdownOpenState((prevState) => 
        prevState.map((item, i) => (i === index ? false : false)));
    }

  };


  const [form] = Form.useForm();
  const onReset = (index) => {
    const updatedAgeGroups = [...ageGroups];

    // Reset the selected property of the items in the specified index
    updatedAgeGroups[index] = updatedAgeGroups[index].map((item) => ({
      ...item,
      selected: false,
    }));

    // Ensure the array has at least 5 elements
    while (updatedAgeGroups[index].length < 5) {
      updatedAgeGroups[index].push({
        id: "",
        name: "",
        range: { min: null, max: null },
        selected: false,
      });
    }

    // Slice the array to keep only the first 5 elements
    updatedAgeGroups[index] = updatedAgeGroups[index].slice(0, 5);
    if (updatedAgeGroups[index]?.length > 4) {
      updatedAgeGroups[index][4] = {
        id: "",
        name: "",
        range: { min: null, max: null },
        selected: false,
      };
    }
    setAgeGroups(updatedAgeGroups);
    form.resetFields();
    const formDetails = form.getFieldsValue();
    formDetails[`ageRanges${index}`]?.splice(0, 5);
    form.setFieldsValue(formDetails);
    setAgeValidationMessage("");
  };

  const convertAgeFormat = (ageItem) => {
    return ageItem.length < 1
      ? []
      : ageItem
          .map(
            (group) =>
              `${group.age_label} (${group.age_value[0]} years-${group.age_value[1]} years)`
          )
          .join(", ");
  };

  const addGroup = (index) => {
    setAgeGroups((prevAgeGroups) => 
      prevAgeGroups.map((ageGroup, i) => 
        i === index ? [
          ...ageGroups[index],
          ...[
            {
              id: "",
              name: "",
              range: {
                min: null,
                max: null,
              },
              selected: false,
            },
          ],
        ] : ageGroup
      )
    );
  };

  const removeRow = (index,groupIndex) => {
    const updatedGroups = ageGroups[groupIndex];
    const defaultGroups = [...updatedGroups.slice(0, 4)];
    const remainingGroups = updatedGroups.slice(4, 9);
    remainingGroups.splice(index, 1);
    const formDetails =form.getFieldsValue()
    // setAgeGroups([...defaultGroups, ...remainingGroups]);
    const newAgeGroup = [...defaultGroups, ...remainingGroups];
    setAgeGroups((prevAgeGroups) =>
      prevAgeGroups.map((ageGroup, i) =>
        i === groupIndex ? newAgeGroup : ageGroup
      )
    );

    formDetails[`ageRanges${groupIndex}`].splice(index,1)
    form.setFieldsValue({
      [`ageRanges${groupIndex}`]: formDetails
    });
  };

  const drodownClicked = (index) => {
    setDropdownOpenState((prevState) => 
      prevState.map((item, i) => (i === index ? true : false)));
    setAgeValidationMessage("");    
  };

  const closeDropdown = (index)=>{
    setDropdownOpenState((prevState) => 
      prevState.map((item, i) => (i === index ? false : false)));
  }

  const formValueChanged = (e) => {
    setAgeValidationMessage("");
  };

  return (
    <>
      <Drawer
        title="Define Target Population"
        placement="right"
        onClose={onClose}
        rootStyle={isOpen ? { marginTop: 57, right: "26.7vw" } : {}}
        open={open}
        width={"auto"}
        extra={
          <Space>
            <CloseOutlined onClick={onClose} />
          </Space>
        }
        footer={
          <Row justify="" gutter={16}>
            <Col>
              <Button
                type="primary"
                onClick={saveFilterDataHandler}
                disabled={
                  (filterCategoryData.length &&
                    filterCategoryData.some(
                      (category) => !category.comparatorName
                    )) ||
                  !editAccessFlag
                }>
                Apply
              </Button>
            </Col>
            <Col>
              <Button disabled={!editAccessFlag} onClick={showConfirm}>
                Reset
              </Button>
            </Col>
          </Row>
        }>
        <Row>
          <Col span={24}>
            {checkComparatorName && (
              <Text type="danger">Respondent description must be unique.</Text>
            )}
          </Col>
        </Row>

        <div
          style={{
            width: "100%",
            display: "flex",
            position: "relative",
            justifyContent: "space-between",
            overflowY: "auto", // Allow vertical scrolling if needed
            // maxHeight: "100%",
          }}>
          {/* <Row> */}
          {filterCategoryData.length > 2 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                position: "absolute",
                top: 0,
                left: 244,
                width: filterCategoryData.length === 5 ? "78%" : "58.5%",
                // marginLeft:-10
              }}>
              <LeftOutlined
                onClick={scrollLeft}
                className="custom-icon-left-outlined"
              />
              <RightOutlined
                onClick={scrollRight}
                className="custom-icon-right-outlined"
              />
            </div>
          ) : null}
          <Col
            style={{
              width: 230,
              height: "100%"
            }}
          >
            {filterCategoryData.slice(0,1)?.map((comparator, index) => {
              return (
                <React.Fragment key={index}>
                  <Col key={comparator.key}>
                    <Row gutter={[16, 16]} style={{ width: 240 }}>
                      <Col
                        span={24}
                        className="gutter-row"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          gap: "0.5rem",
                        }}>
                        <Row>
                          <Col
                            style={{
                              marginTop: 10,
                              width: "100%",
                              height: 42
                            }}>
                              <strong>Target Population Description</strong>
                          </Col>
                        </Row>
                      </Col>
                      {comparator?.filters?.map((category) => {
                        return (
                          <Col
                            span={category.span}
                            className="gutter-row"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              gap: "0.5rem",
                            }}>
                            <span style={{  height: category.type === "dropdown" ? 48 : 40 }}> 
                              <strong>{category.categoryLabel}</strong>
                              {category.isInfoIcon && (
                                <Tooltip title={category.infoMessage}>
                                  <img
                                    width={15}
                                    src={InfoIcon}
                                    alt={"info"}
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: 5,
                                      verticalAlign: "middle",
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </span>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                  {filterCategoryData?.length != index + 1 && (
                    <Divider
                      type="vertical"
                      className="divider-vertical-drawer"
                    />
                  )}
                </React.Fragment>
              );
            })}
          </Col>
          <Divider type="vertical" style={{ height: "auto" }} />
          <div
            ref={containerRef}
            style={{
              width: "100%",
              maxWidth: "35vw",
              padding: "0px 10px",
              overflowX: "auto",
              display: "flex",
              position: "relative",
            }}>
            {filterCategoryData?.map((comparator, index) => {
              return (

                <React.Fragment key={index}>
                  <Col key={comparator.key}>

                    <Row gutter={[16, 16]} style={{ width: 264 }}>
                      <Col
                        span={24}
                        className="gutter-row"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          gap: "0.5rem",
                        }}>
                        <Row>
                          {/* {filterCategoryData.length > 1 && (
                            <strong>{index + 1}.</strong>
                          )}
                          <strong>Target Population Description</strong> */}
                          <Col
                            style={{
                              marginTop: 10,
                              width:
                                filterCategoryData.length > 1 ? "80%" : "100%",
                            }}>
                            <Input
                              maxLength={50}
                              placeholder="Add Name"
                              disabled={!editAccessFlag}
                              value={comparator.comparatorName}
                              onChange={(event) =>
                                comparatorRenameHandler(event, index)
                              }
                            />
                          </Col>
                          {filterCategoryData.length > 1 && (
                            <Col style={{ marginTop: 12 }}>
                              <img
                                style={{ marginLeft: 8, cursor: "pointer" }}
                                alt={"Delete"}
                                src={DeleteIcon}
                                onClick={() =>
                                  deleteCriteria(
                                    index,
                                    comparator.comparatorName
                                  )
                                }
                              />
                            </Col>
                          )}
                        </Row>
                      </Col>
                      {comparator?.filters?.map((category) => {
                        return category.type === "dropdown" ? (
                          <Col
                            span={category.span}
                            className="gutter-row"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              gap: "0.5rem",
                            }}>
                            <span>
                              {/* <strong>{category.categoryLabel}</strong> */}
                              {/* {category.isInfoIcon && (
                                <Tooltip title={category.infoMessage}>
                                  <img
                                    width={15}
                                    src={InfoIcon}
                                    alt={"info"}
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: 5,
                                      verticalAlign: "middle",
                                    }}
                                  />
                                </Tooltip>
                              )} */}
                            </span>
                            <Select
                              getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode
                              }
                              {...selectProps}
                              onDropdownVisibleChange={(event) =>
                                dropdownToggleHandler(event, category, index)
                              }
                              onChange={(event) =>
                                dropdownChangeHandler(event, category, index)
                              }
                              options={category.options}
                              value={category.selectedValue}
                              loading={category.loading}
                              disabled={
                                // checkDisabled(category.categoryKey, index)
                                category.categoryKey === "stage" &&
                                !stageDisabledFlag[index]
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                        ) : category.type === "single-select-dropdown" ? (
                          <Col
                            span={category.span}
                            className="gutter-row"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              gap: "0.5rem",
                            }}>
                            <span></span>
                            <Select
                              getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode
                              }
                              {...singleSelectProps}
                              showSearch
                              filterOption={(input, option) => {
                                const labelMatch = option.label
                                  .toLowerCase()
                                  .includes(input.toLowerCase());
                                const quesIdMatch = option.ques_id
                                  ?.toLowerCase()
                                  .includes(input.toLowerCase());
                                return labelMatch || quesIdMatch;
                              }}
                              onDropdownVisibleChange={(event) =>
                                dropdownToggleHandler(event, category, index)
                              }
                              onChange={(event) =>
                                dropdownChangeHandler(event, category, index)
                              }
                              options={category.options}
                              value={category.selectedValue}
                              loading={category.loading}
                              disabled={
                                category.categoryKey === "question_id" &&
                                questionCategoryFlag?.length < 1
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                        ) : category.type === "custom-label" ? (
                          <Col
                            span={category.span}
                            className="gutter-row"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              gap: "0.5rem",
                            }}>
                            <span></span>
                            <div style={{ height: 26 }}></div>
                          </Col>
                        ) : category.type === "tree" ? (
                          <Col
                            span={category.span}
                            className="gutter-row"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              gap: "0.5rem",
                            }}>
                            {/* <strong>{category.categoryLabel}</strong> */}
                            <TreeSelect
                              getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode
                              }
                              {...tProps}
                              onDropdownVisibleChange={(event) =>
                                dropdownToggleHandler(event, category, index)
                              }
                              onChange={(event) =>
                                dropdownChangeHandler(
                                  event,
                                  category,
                                  index,
                                  comparator.comparatorName
                                )
                              }
                              treeData={
                                !editAccessFlag
                                  ? treeDataWithDisabled(category.options)
                                  : category.options
                              }
                              value={category.selectedValue}
                              loading={category.loading}
                              disabled={
                                (category.categoryKey === "lov_id" &&
                                questionResponseFlag?.length < 1
                                  ? true
                                  : false) ||
                                (category.categoryKey === "other_indication" &&
                                otherIndicationFlag[index]
                                  ? true
                                  : false)
                              }
                              showSearch
                              filterTreeNode={(inputValue, treeNode) =>
                                treeNode.title
                                  .toLowerCase()
                                  .includes(inputValue.toLowerCase())
                              }
                            />
                          </Col>
                        ) : category.type === "survey-year-tree" ? (
                          <Col
                            span={category.span}
                            className="gutter-row"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              gap: "0.5rem",
                            }}>
                            {/* <strong>{category.categoryLabel}</strong> */}
                            <TreeSelect
                              getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode
                              }
                              {...tPropsSurveyYear}
                              onDropdownVisibleChange={(event) =>
                                dropdownToggleHandler(event, category, index)
                              }
                              onChange={(event) =>
                                dropdownChangeHandler(
                                  event,
                                  category,
                                  index,
                                  comparator.comparatorName
                                )
                              }
                              treeData={
                                !editAccessFlag
                                  ? treeDataWithDisabled(category.options)
                                  : category.options
                              }
                              value={category.selectedValue}
                              loading={category.loading}
                            />
                          </Col>
                        ) : category.type === "dropdown-custom" ? (
                          <Col
                            span={category.span}
                            className="gutter-row"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              gap: "0.5rem",
                            }}>
                            {/* <strong>{category.categoryLabel}</strong> */}
                            <Select
                              getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode
                              }
                              ref={selectRef}
                              {...selectProps}
                              open={dropdownOpenState[index]}
                              onDropdownVisibleChange={(event) =>
                                dropdownToggleHandler(event, category, index)
                              }
                              onChange={(event) =>
                                dropdownChangeHandler(event, category, index)
                              }
                              onClick={() => drodownClicked(index)}
                              options={category.options}
                              value={convertAgeFormat(category.selectedValue)}
                              loading={category.loading}
                              className="age-dropdown"
                              placeholder="All"
                              dropdownStyle={{ width: 320, minWidth: 320 }}
                              dropdownRender={(menu) => (
                                <div style={{ padding: 10 }}>
                                  {/* <div><CloseOutlined onClick={()=>closeDropdown(index)} /></div> */}
                                  <Row
                                    style={{
                                      fontWeight: "bold",
                                      marginBottom: "10px",
                                    }}>
                                    <Col style={{ paddingLeft: 7 }} span={12}>
                                      Age Group
                                    </Col>
                                    <Col span={12}>Range in Years</Col>
                                  </Row>
                                  {ageGroups[index]
                                    ?.slice(0, 4)
                                    .map((group) => (
                                      <>
                                        <Row
                                          key={group.id}
                                          style={{
                                            marginBottom: "10px",
                                            alignItems: "center",
                                          }}>
                                          <Col
                                            style={{ paddingLeft: 7 }}
                                            span={12}>
                                            <Checkbox
                                              checked={group.selected}
                                              onChange={() =>
                                                handleCheckboxChange(
                                                  group.id,
                                                  index
                                                )
                                              }>
                                              {group.name}
                                            </Checkbox>
                                          </Col>
                                          <Col span={12}>
                                            {group.range.min !== null &&
                                            group.range.max !== null ? (
                                              `(${group.range.min} - ${group.range.max} years)`
                                            ) : (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}></div>
                                            )}
                                          </Col>
                                        </Row>
                                      </>
                                    ))}

                                  <Col>
                                    <Form
                                      form={form}
                                      name={`dynamic_form_nest_item_${index}`}
                                      onFinish={(values) =>
                                        onFinish(values, index)
                                      }
                                      onValuesChange={formValueChanged}
                                      clearOnDestroy={true}
                                      style={{
                                        maxWidth: 600,
                                      }}
                                      autoComplete="off">
                                      <Form.List name={`ageRanges${index}`}>
                                        {(fields, { add, remove }) => (
                                          <>
                                            {ageGroups[index]
                                              ?.slice(4, 9)
                                              .map((group, i) => (
                                                <Space
                                                  key={i}
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}>
                                                  <Form.Item
                                                    name={[i, "checked"]}
                                                    valuePropName="checked"
                                                    initialValue={
                                                      group.selected
                                                    }>
                                                    <Checkbox />
                                                  </Form.Item>
                                                  <Form.Item
                                                    name={[i, "name"]}
                                                    initialValue={group.name}>
                                                    <Input placeholder="Age Group Name" />
                                                  </Form.Item>
                                                  <Form.Item
                                                    name={[i, "minAge"]}
                                                    initialValue={
                                                      group.range.min
                                                    }>
                                                    <InputNumber
                                                      min={1}
                                                      style={{ width: 60 }}
                                                      max={120}
                                                      placeholder="Min Age"
                                                    />
                                                  </Form.Item>
                                                  <Form.Item
                                                    name={[i, "maxAge"]}
                                                    initialValue={
                                                      group.range.max
                                                    }>
                                                    <InputNumber
                                                      min={1}
                                                      style={{ width: 60 }}
                                                      max={120}
                                                      placeholder="Max Age"
                                                    />
                                                  </Form.Item>
                                                  <Form.Item style={{}}>
                                                    <DeleteOutlined
                                                      onClick={() =>
                                                        removeRow(i, index)
                                                      }
                                                    />
                                                  </Form.Item>
                                                </Space>
                                              ))}
                                            <Form.Item
                                              style={{ marginBottom: 0 }}>
                                              <Button
                                                type="text"
                                                onClick={() => addGroup(index)}
                                                block
                                                icon={<PlusOutlined />}
                                                style={{
                                                  textAlign: "left",
                                                  paddingTop: 10,
                                                }}
                                                disabled={
                                                  ageGroups[index]?.length >= 9
                                                }>
                                                Add New Age Group
                                              </Button>
                                            </Form.Item>
                                          </>
                                        )}
                                      </Form.List>
                                      <Form.Item style={{ marginBottom: 0 }}>
                                        {ageValidationMessage && (
                                          <div>
                                            <br />
                                            <Text type="danger">
                                              {ageValidationMessage}
                                            </Text>
                                            <br />
                                          </div>
                                        )}
                                        <br />
                                        <Button
                                          disabled={
                                            ageValidationMessage.length > 0
                                          }
                                          type="primary"
                                          htmlType="submit"
                                          onClick={() =>
                                            onFinish(
                                              form.getFieldsValue(),
                                              index
                                            )
                                          }>
                                          Apply
                                        </Button>
                                        <Button
                                          type="text"
                                          onClick={() => onReset(index)}>
                                          Reset
                                        </Button>
                                      </Form.Item>
                                    </Form>
                                  </Col>
                                </div>
                              )}
                            />
                          </Col>
                        ) : (
                          <></>
                        );
                      })}
                    </Row>
                  </Col>
                  {filterCategoryData?.length != index + 1 && (
                    <Divider
                      type="vertical"
                      className="divider-vertical-drawer"
                    />
                  )}
                </React.Fragment>
              );
            })}
          </div>
          <Divider type="vertical" style={{ height: "auto" }} />
          {editAccessFlag && filterCategoryData?.length < 5 && (
            <Col
              style={{
                width: 120,
                border: "1px solid #62D2D1",
                background: "#F3FCFE",
                cursor: "pointer",
              }}
              onClick={addComparator}>
              <div style={{ textAlign: "center", marginTop: "10%" }}>
                <img alt={"Plus Icon"} src={PlusIcon} />
                <div
                  style={{
                    color: "#2D8B93",
                  }}>
                  Add Comparator Population{" "}
                </div>
              </div>
            </Col>
          )}
          {/* </Row> */}
        </div>
      </Drawer>
    </>
  );
};
export default RespondentFilter;
