import { createSlice } from "@reduxjs/toolkit";
import { PEBInitialState } from "./PEBInitialState";

export const PEBCommonSlice = createSlice({
  name: "PEBCommon",
  initialState: {
    ...PEBInitialState,
  },
  reducers: {
    setUserDetail: (state, action) => {
      state.userDetail = action.payload;
    },
    setFilterAPIData: (state, action) => {
      state.filterAPIData = action.payload;
    },
    setNavigationTab: (state, action) => {
      state.navigationTab = action.payload;
    },
    setFilterApplyData: (state, action) => {
      state.filterApplyData = action.payload;
    },
    setExploreFilterApplyData: (state, action) => {
      state.exploreFilterApplyData = action.payload;
    },
    setDisableData: (state, action) => {
      state.disableData = action.payload;
    },
    setNotificationData: (state, action) => {
      state.notificationData = action.payload;
    },
    setSearchTextExplore: (state, action) => {
      state.searchTextExplore = action.payload;
    },
    setRemoveTagData: (state, action) => {
      state.removeTagData = action.payload;
    },
    setIsFindingsReportIsOpen: (state, action) => {
      state.findingsReport.isOpen = action.payload;
    },
    setIsFindingsReportFullWidth: (state, action) => {
      state.findingsReport.isFullWidth = action.payload;
    },

    setFindingsReportAnalysisId: (state, action) => {
      state.findingsReport.analysisId = action.payload;
    },
    setFindingReportAddInsight: (state, action) => {
      state.findingsReport.insight_list.push(action.payload);
    },

    setFindingsReportAddDataToInsights: (state, action) => {
      const { index, data, supp_card_id, reason, supp_card_order } =
        action.payload;

      state.findingsReport.insight_list[index].questions_list.push({
        supporting_data: data,
        supp_card_id,
        reason,
        supp_card_order,
      });
    },
    setFindingsReportMoveInsight: (state, action) => {
      const { draggedIndex, droppedIndex } = action.payload;
      const [draggedInsight] = state.findingsReport.insight_list.splice(
        draggedIndex,
        1
      );
      state.findingsReport.insight_list.splice(droppedIndex, 0, draggedInsight);
    },
    setFindingsReportMoveSupportingCard: (state, action) => {
      const { draggedIndex, droppedIndex } = action.payload;
      const [dragedData] = state.findingsReport.supporting_data.splice(
        draggedIndex,
        1
      );
      state.findingsReport.supporting_data.splice(droppedIndex, 0, dragedData);
    },
    setFindingReportUpdateInsight: (state, action) => {
      const { index, data } = action.payload;

      state.findingsReport.insight_list[index].insight_text = data;
    },
    setFindingReportDeleteInsight: (state, action) => {
      const insight_id = action.payload;
      if (insight_id) {
        const indexToDelete = state.findingsReport.insight_list.findIndex(
          (insight) => insight.insight_id === insight_id
        );

        if (indexToDelete !== -1) {
          state.findingsReport.insight_list.splice(indexToDelete, 1);
        }
      }
    },

    setFindingsReportSupportingData: (state, action) => {
      state.findingsReport.supporting_data = action.payload;
    },

    setFindingReportDeleteSupportingData: (state, action) => {
      const { index, insight_id, supporting_data } = action.payload;
      if (insight_id) {
        state.findingsReport.insight_list
          .find((insight) => insight.insight_id === insight_id)
          ?.questions_list.splice(index, 1);
        // if (flag) {
        // delete state.findingsReport.supportingDataMap[key];
        // }
        // }
      } else {
        state.findingsReport.supporting_data.splice(index, 1);
      }
    },

    setFindingsReportAddSupportingData: (state, action) => {
      const { data, preference, question, reason, respondents , combined_indication } =
        action.payload;
      if (data.responseType === "Preference") {
        let flag = false;
        state.findingsReport.supporting_data.forEach((unmappedData) => {
          if (
            unmappedData?.supporting_data?.preference === preference &&
            unmappedData?.supporting_data?.ques_text === question &&
            unmappedData?.supporting_data?.respondents === respondents &&
            unmappedData?.supporting_data?.combined_indication === combined_indication

            // &&
            // preference !== ""
          ) {
            // You can access the unmappedData and push the reason to details array
            if (Array.isArray(unmappedData.reason)) {
              unmappedData.reason.push(reason[0]);
              flag = true;
            }
          }

          if (
            unmappedData?.supporting_data[0]?.preference === preference &&
            unmappedData?.supporting_data[0]?.ques_text === question &&
            unmappedData?.supporting_data[0]?.respondents === respondents &&
            unmappedData?.supporting_data[0]?.combined_indication === combined_indication


            // &&
            // preference !== ""
          ) {
            // You can access the unmappedData and push the reason to details array
            if (Array.isArray(unmappedData.reason)) {
              unmappedData.reason.push(reason[0]);
              flag = true;
            }
          }
        });
        state.findingsReport.insight_list.forEach((insight) => {
          insight?.questions_list?.forEach((questions) => {
            if (
              questions?.supporting_data?.ques_text === question &&
              questions?.supporting_data?.preference === preference &&
              questions?.supporting_data?.respondents === respondents

              // &&
              // preference !== ""
            ) {
              questions.reason.push(reason[0]);
              flag = true;
            }
            if (
              questions?.supporting_data[0]?.ques_text === question &&
              questions?.supporting_data[0]?.preference === preference &&
              questions?.supporting_data[0]?.respondents === respondents

              // &&
              // preference !== ""
            ) {
              questions.reason?.push(reason[0]);
              flag = true;
            }
          });
        });

        if (!flag) state.findingsReport.supporting_data?.push(data);
      } else {
        state.findingsReport.supporting_data.push(data);
      }
    },

    setFindingReportData: (state, action) => {
      const { insight_list, supporting_data } = action.payload;
      state.findingsReport.insight_list = insight_list;
      state.findingsReport.supporting_data = supporting_data;
    },

    setFindingsReportAnalysisData: (state, action) => {
      const {
        name,
        description,
        quesMaxUpdatedTs,
        id,
        last_updated,
        edit_access_flag,
      } = action.payload;
      state.findingsReport.analysisData.name = name;
      state.findingsReport.analysisData.description = description;
      state.findingsReport.analysisData.id = id;
      state.findingsReport.analysisData.quesMaxUpdatedTs = quesMaxUpdatedTs;
      state.findingsReport.analysisData.last_updated = last_updated;
      state.findingsReport.analysisData.edit_access_flag = edit_access_flag;
    },
    setRefreshAnalysisList: (state, action) => {
      state.refreshAnalysisList = action.payload;
    },
    setHighlightCardData: (state, action) => {
      state.highlightCardData = action.payload;
    },
    setFindingsReportMoveSupportingDataInsideInsights: (state, action) => {
      const { insight_id, draggedIndex, droppedIndex } = action.payload;
      if (insight_id) {
        const [supportingDataOfInsights] = state.findingsReport.insight_list
          .find((insight) => insight.insight_id === insight_id)
          ?.questions_list.splice(draggedIndex, 1);

        state.findingsReport.insight_list
          .find((insight) => insight.insight_id === insight_id)
          ?.questions_list.splice(droppedIndex, 0, supportingDataOfInsights);
      }
    },
    setHasAccessToReport: (state, action) => {
      state.hasAccessToReport = action.payload;
    },
    setSupportingData: (state, action) => {
      state.supportingData = action.payload;
    },

    setSelectedCardId: (state, action) => {
      state.findingsReport.selectedCard = action.payload;
    },

    setReasonsTableParams: (state, action) => {
      state.tableParam = action.payload;
    },
    setDeleteCardRefresh: (state, action) => {
      state.deleteCardRefresh = action.payload;
    },
    setRecordData:(state, action) => {
      state.recordData = action.payload;
    },
    setFilterData:
    (state, action) => {
      state.filterData = action.payload;
    },
    setAnalysisLockFlag:
    (state, action) => {
      state.analysisLockFlag = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserDetail,
  setRecordData,
  setFilterAPIData,
  setNavigationTab,
  setFilterApplyData,
  setExploreFilterApplyData,
  setNotificationData,
  setSearchTextExplore,
  setIsFindingsReportIsOpen,
  setIsFindingsReportFullWidth,
  setFindingsReportAnalysisId,
  setFindingsReportAddSupportingData,
  setFindingReportAddInsight,
  setFindingsReportAddDataToInsights,
  setFindingsReportMoveInsight,
  setFindingReportUpdateInsight,
  setFindingReportDeleteInsight,
  setFindingReportDeleteSupportingData,
  setFindingReportData,
  setFindingsReportAnalysisData,
  setFindingsReportMoveSupportingCard,
  setRefreshAnalysisList,
  setHighlightCardData,
  setFindingsReportMoveSupportingDataInsideInsights,
  setHasAccessToReport,
  setFindingsReportDragFlag,
  setSelectedCardId,
  setSelectedInsightId,
  setReasonsTableParams,
  setDeleteCardRefresh,
  setSupportingData,
  setFilterData,
  setDisableData,
  setAnalysisLockFlag
} = PEBCommonSlice.actions;

export default PEBCommonSlice.reducer;
