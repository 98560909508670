import React, { useState, useEffect } from "react";
import "./ExploreResponseDetails.scss";
import { useSelector, useDispatch } from "react-redux";
import { Collapse, Table, Space, Tooltip, Row, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import AddToFindingsIcon from "../../assets/icons/add-to-findings-new.svg";
import AddToFindingsDisabledIcon from "../../assets/icons/add-to-findings-disabled.svg";
import ResponseDetailIcon from "../../assets/icons/respone-details-new.svg";
import UnpinIcon from "../../assets/icons/unpin-icon.svg";
import PinnedIcon from "../../assets/icons/pinned-icon.svg";
import ResponseDetails from "../response-details-drawer/ResponseDetails";
import NeutralIcon from "../../assets/icons/Neutral-icon.svg";
import AgreeIcon from "../../assets/icons/Agree-blue-thumb.svg";
import Disagree from "../../assets/icons/Disagree-orange-thumb.svg";
import FindingReportSettingsModal from "../finding-report-setting-modal/FindingReportSettingsModal";
import {
  getResponsesCategory,
  getQuestionsByCategory,
  setPinUnpinQuestions,
} from "../../services/ResponsesAPI";
import {
  setFindingsReportAddSupportingData,
  setHighlightCardData,
  setSupportingData,
  setFilterData,
  setRecordData,
} from "../../reducer/PEBCommonSlice";
import { saveFindingsReportData } from "../../services/ExperienceBankAPI";
// const { Panel } = Collapse;

const ExploreResponseDetails = (props) => {
  const edit_access_flag = useSelector(
    (state) => state.PEBCommon?.hasAccessToReport
  );
  const deleteCardRefresh = useSelector(
    (state) => state.PEBCommon?.deleteCardRefresh
  );
  const userInfo = useSelector((state) => state.PEBCommon?.userDetail); // get User details
  const userId = userInfo.user_id;
  const dispatch = useDispatch();
  const searchString = useSelector(
    (state) => state.PEBCommon?.searchTextExplore
  );  
  const filterAppliedData = useSelector(
    (state) => state.PEBCommon?.exploreFilterApplyData
  );
  // const analysisId = useSelector(
  //   (state) => state.PEBCommon?.findingsReport.analysisId
  // );

  const analysisData = useSelector(
    (state) => state.PEBCommon?.findingsReport.analysisData
  );
  const filterData = useSelector((state) => state.PEBCommon?.filterData);
  const disableData= useSelector(
    (state) => state.PEBCommon?.disableData
  );
  const [loading, setLoading] = useState(false);
  const [respondentFilterFlag, setRespondentFilterFlag] = useState(false);
  const [categoriesList, setCategoriesList] = useState();
  const [categoriesAPIData, setcategoriesAPIData] = useState();
  const [responseRowRecord, setResponseRowRecord] = useState();
  const [categoryName, setCategoryName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  //const [recordData, setRecordData] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  //const [filterData, setFilterData] = useState();
  const highlightRow = useSelector(
    (state) => state.PEBCommon?.highlightCardData
  );
  
  // ******POC TO HIGHLIGHT********//
  const [activeKeys, setActiveKeys] = useState([]);
  // const [rowToHighLight, setRowToHighLight] = useState();

  const columns = [
    {
      title: "Questions",
      dataIndex: "question",
      key: "question",
      maxWidth: "10vw",
      width: "10vw",
      fixed: "left",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      fixed: "right",
      // maxWidth: "6vw",
      width: 70,
      render: (_, record) => (
        <Space size="small" style={{ display: "inline-block" }}>
          {!record.addToFindingsFlag  ? (
            <Tooltip
              title={
                <div>
                  Create or open a report in <br />
                  order to Add to Findings
                </div>
              }>
              <Space>
                <img
                  alt={"Build/Update Report"}
                  src={AddToFindingsDisabledIcon}
                />
                <span style={{ color: "#B2B0B6" }}>Add to Report</span>
              </Space>
            </Tooltip>
          ) : (
            <Space>
              <img
                alt={"Build/Update Report"}
                style={{ cursor: "pointer" }}
                src={AddToFindingsIcon}
                onClick={() => addToFindingsModal(record)}
              />
              <span style={{ color: "#2F6F7B" }}>Add to Report</span>
            </Space>
          )}

          {record.pinnedFlag ? (
            <Space>
              <img
                style={{ cursor: "pointer" }}
                alt={"UnPin Question"}
                src={PinnedIcon}
                onClick={() => pinUnpinQuestionClicked(record, "unpin")}
              />
              <span style={{ color: "#2F6F7B" }}>Pinned</span>
            </Space>
          ) : (
            <Space>
              <img
                style={{ cursor: "pointer" }}
                alt={"Pin Question"}
                src={UnpinIcon}
                onClick={() => pinUnpinQuestionClicked(record, "pin")}
              />
              <span style={{ color: "#2F6F7B" }}> Pin</span>
            </Space>
          )}
        </Space>
      ),
    },
  ];
  //const [supportingData, setSupportingData] = useState([]);
  // const isOpen = useSelector((state)=>state.PEBCommon.findingsReport.isOpen)
  const addToFindingsModal = (inputJSON) => {
    setLoading(true);
    let analysis_id = localStorage.getItem("analysisId");
    const {
      question,
      id,
      responseType,
      pinnedFlag,
      categoryName,
      n_size,
      nSize,
      addToFindingsFlag,
      viewAllResponseFlag,
      panelKey,
      ques_type,
      ...criteria
    } = inputJSON;

    function transformJSON() {
      const transformedData = Object.keys(criteria).map((criteriaName) => {
        const criteriaInfo = criteria[criteriaName];
        const criteriaResponses =
          // Array.isArray(criteriaInfo)  ?
          criteriaInfo.map((response) => ({
            response: responseType === 'Grid' ? `${response.response} - ${response.combined_indication}` : response.response,
            perc_respondents: response.perc_respondents,
            nSize: response.n_size,
            hover_string: response.hover_string,
          }));
        // : [
        //     {
        //       name: "disagree_percentage",
        //       value: criteriaInfo.disagree_percentage,
        //       nSize: criteriaInfo.n_size,
        //       hover_string: criteriaInfo.disagree_hover_string,
        //     },
        //     {
        //       name: "neutral_percentage",
        //       value: criteriaInfo.neutral_percentage,
        //       nSize: criteriaInfo.n_size,
        //       hover_string: criteriaInfo.neutral_hover_string,
        //     },
        //     {
        //       name: "agree_percentage",
        //       value: criteriaInfo.agree_percentage,
        //       nSize: criteriaInfo.n_size,
        //       hover_string: criteriaInfo.agree_hover_string,
        //     },
        //   ];

        return {
          criteria_name: criteriaName,
          nSize: criteria[criteriaName]?.n_size,
          details: criteriaResponses,
        };
      });

      return {
        ques_text: question,
        ques_id: id,
        response_type: responseType,
        criteria_details: transformedData,
        category_name: categoryName,
        panel_key: pinnedFlag ? "1" : panelKey,
      };
    }
    const result = transformJSON(inputJSON);
    const addSupportingData = async (suppCardOrder) => {
      const payload = {
        user_id: userId,
        analysis_id: analysis_id,
        save_type: "supp_ques",
        order_data: {},
        prev_insight_id: "",
        insight_id: "",
        edit_insight_flag: false,
        insight_text: "",
        ques_id: result.ques_id,
        insight_order: 2,
        ques_order: 1,
        insight_ques_order: 0,
        supporting_data: JSON.stringify(result),
        preference: "",
        reason: "",
        supp_card_id: "",
        supp_card_order: +suppCardOrder + 1,
        added_from: "category_responses",
        combined_indication:"",
      };
      let response = await saveFindingsReportData(payload);
      return response;
    };
    if (analysis_id != null) {
      let suppCardOrder = localStorage.getItem("suppCardOrder");

      addSupportingData(suppCardOrder).then((data) => {
        setLoading(false);

        dispatch(
          setFindingsReportAddSupportingData({
            data: {
              supp_card_id: data.result.supp_card_id,
              ques_order: data.result.max_supp_card_order,
              supp_card_order: +suppCardOrder + 1,
              ques_id: result.ques_id,
              supporting_data: result,
            },
          })
        );
        localStorage.setItem("suppCardOrder", data.result.max_supp_card_order);

        onChange([panelKey]);
      });
    }
    dispatch(setRecordData(inputJSON));
  };

  const responseDetailDrawer = (state, row) => {
    setRespondentFilterFlag(state);
    setResponseRowRecord(row);
  };

  const truncateText = (text, maxLength) => {
    if (!text) return ''; // Handle the case where text might be undefined or null
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + '...';
  };

  const buildOutput = (quesList, categoryName, panelKey, scroll) => {
    let result = quesList;
    let resType = '' ;
    const finalData = [];
    const columns1 = [];
    let xWidth = 800;
   result.forEach((item) => {
     resType =item.response_type;
     const result1 = {};
     let resSize = { nSize: 0 };
     console.log(item.criteria_details);
     item.criteria_details.forEach((item1) => {
       const { criteria_name, details } = item1;
       result1[criteria_name] = details;

       console.log("item1.details ", item.response_type ,item1.details);
      //  resSize = {
      //    nSize: item1.details.n_size && item.response_type !== 'Grid'
      //      ? item1.details[0].n_size
      //      : item1.details
      //          .map((item) => item.n_size) // Extract n_size values
      //          .filter((nSize) => nSize !== undefined), // Filter out undefined values
      //  };
      if (resType === 'Grid') {
        // If Grid, store all nSize values
        resSize.nSize = details
          .map((detail) => detail.n_size)
          .filter((nSize) => nSize !== undefined); // Get all valid nSize values
      } else {
        // If not Grid, store only the first element's nSize
        resSize.nSize = [details[0]?.n_size] || [0];
      }
     });
     console.log(resSize);
     let obj1 = {
       question: item.ques_text,
       id: item.ques_id,
       n_size: resSize.nSize,
       responseType: item.response_type,
       pinnedFlag: item.pinned_flag,
       categoryName: item.ques_category_name,
       viewAllResponseFlag: item.hide_response_details_flag,
       panelKey: panelKey,
       addToFindingsFlag: disableData,
       ques_type: item.ques_type,
     };
     let obj = {};
     if (item.criteria_details.length > 1) {
       obj = { ...obj1, ...result1 };
     } else {
       obj = { ...obj1, ...result1, ...resSize };
     }

     finalData.push(obj);
   });

   setDataSource(finalData);   

   filterAppliedData.forEach((item, index) => {
     const obj = {
       title: filterData.length > 1 ? item.comparator_name : "Responses",
       dataIndex: item.comparator_name,
       key: index + 1,
       width: 160,
       render: (_, record) => (
         <Space size="small">
           {
             <div>
               {record[item.comparator_name]?.map((obj) => 
                 (obj?.response !== "Open text" ? (<div>
                   <Tooltip
                     title={`${obj?.hover_string} of ${obj?.n_size} Respondents`}>
                     <div
                       style={{
                         fontSize: 20,
                         fontWeight: 700,
                         cursor: "pointer",
                         width:54,
                         display:"inline-flex"
                       }}>
                       {obj?.perc_respondents}%
                     </div>
                   </Tooltip>
                   <div
                     style={{
                       fontSize: 14,
                       fontWeight: 400,
                       marginLeft: 10,
                       width: obj?.combined_indication ? "" : 200,
                       display: "inline-flex",
                     }}>
                     {obj?.response}
                   </div>
                   {obj?.combined_indication && (
                     <Tooltip title={obj?.combined_indication}>
                       <span
                         style={{
                           fontSize: 16,
                           fontWeight: 700,
                           cursor: "pointer",
                         }}>
                         {" - " + truncateText(obj?.combined_indication, 20)}
                       </span>
                     </Tooltip>
                   )}
                 </div>): <Tooltip
                     title={`${obj?.n_size} Respondents`}>
                     <div  style={{
                       fontSize: 16,
                       fontWeight: 400,
                       display: "inline-flex",
                     }}>Open text responses</div>
                   </Tooltip>)
               )}
               {record[item.comparator_name] &&
                 !record?.viewAllResponseFlag && (
                   <div style={{ marginTop: 12 }}>
                     <a
                       className="view-response-anchor"
                       onClick={() => responseDetailDrawer(true, record)}>
                       View All Responses
                     </a>
                   </div>
                 )}
             </div>
             // }
             // </div>
           }
         </Space>
       ),
     };
     columns1.push(obj);
     if (filterAppliedData.length < 2) {
       const obj = {
         title: "Sample Size",
         dataIndex: "nSize",
         key: "nSize",
         width: 50,
         render: (text) => (
           <>{console.log("resType ---", text , text.length )}
           {text && text.map((obj)=><div style={{marginBottom:'1rem'}}>{obj}</div>)}
           {/* {resType === 'Grid' ? (record[item.comparator_name]?.map((obj) => (
               <div
                 style={{
                   display: "flex",
                   alignItems: "",
                   minHeight:
                     record[item.comparator_name].length === 3
                       ? "2rem"
                       : "4rem",
                 }}>
                 {obj.n_size}
               </div>
             ))) : (<div>{record[item.comparator_name] && record[item.comparator_name][0]?.n_size}</div>)} */}
             
           </>
         ),
       };
       // xWidth = 1100;
       columns1.push(obj);
     }
   });
    let analysis = localStorage.getItem("analysisId") || null;
    xWidth = analysis
      ? result[0].criteria_details.length > 3
        ? result[0].criteria_details.length * 450
        : result[0].criteria_details.length * 500
      : result[0].criteria_details.length * 420;

    const colObj = [...columns];
    colObj.splice(1, 0, ...columns1);

    //setColumns(colObj);

    // setCategoryItems(categoriesList)
    let catList = categoriesList;
    catList.forEach((item) => {
      if (item.category_name === categoryName) {
        item.children = (
          <div>
            <Table
              className="question-list-table"
              rowClassName={(record) => `${record.id}`}
              dataSource={finalData}
              columns={colObj}
              pagination={false}
              scroll={{
                x: xWidth,
                y: 400,
              }}
            />
          </div>
        );
      }
    });
    setCategoriesList(catList);

    if (scroll) {
      const intervalId = setInterval(() => {
        const element = document.getElementsByClassName(highlightRow.ques_id);

        if (element.length > 0) {
          setTimeout(() => {
            element[0]?.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            });
            element[0].classList.toggle("row-highlight");
            setTimeout(() => {
              element[0].classList.toggle("row-highlight");
              dispatch(setHighlightCardData({}));
            }, 5000);
          }, 100);
          clearInterval(intervalId);
        }
      }, 100);
    }
    // dispatch(setHighlightCardData(null));
  };

  const onChange = (key, scroll) => {
    setActiveKeys(key);

    const lastElement = key[key.length - 1];

    const filteredObject = categoriesList?.filter(
      (_, index) => index === lastElement - 1
    )[0];

    if (filteredObject) {
      getCategoryQuestions(
        filteredObject?.category_name,
        lastElement,
        scroll
      ).finally(() => setLoading(false));
      setCategoryName(filteredObject.category_name);
      getCategoryQuestions("Pinned");
    }
  };

  //to get all the response Categories
  const getCategoriesForResponses = async () => {
    setLoading(true);

    const payload = {
      user_id: userId,
      screen_name: "responses",
      search_string: searchString || "",
      analysis_id: localStorage.getItem("analysisId") || "",
      analysis_refresh_date: analysisData?.last_updated || "",
      ques_max_updated_ts: analysisData?.quesMaxUpdatedTs || "",
    };

    let response = await getResponsesCategory(payload);

    dispatch(setFilterData(response?.result?.comparator_data));
    setcategoriesAPIData(response?.result.categories_data);
    const categoriesData = response?.result.categories_data;
    const catList = [];
    categoriesData.forEach((item, index) => {
      const obj = {
        key: index + 1,
        category_name: item.category_name,
        label: (
          <div style={{ display: "flex" }}>
            <span className="catName">
              {item.category_name} ({item.total_questions})
            </span>
            <div className="catDesc">{item.category_description}</div>
          </div>
        ),
        children: "",
      };
      catList.push(obj);
    });

    setCategoriesList(catList);
    setLoading(false);
  };

  //to get all the questions for the category
  const getCategoryQuestions = async (categoryName, panelKey, scroll) => {
    const payload = {
      user_id: userId,
      screen_name: "response_details",
      search_string: searchString || "",
      category_name: categoryName,
      analysis_id: localStorage.getItem("analysisId") || "",
      analysis_refresh_date: analysisData?.last_updated || "",
      ques_max_updated_ts: analysisData?.quesMaxUpdatedTs || "",
      comparator_data:
        filterData.length > 0
          ? filterData
          : [
              {
                age: null,
                race: null,
                gender: null,
                ethnicity: null,
                geography: null,
                indication: null,
                comparator_nm: "",
                origin_country: null,
                sub_indication: null,
                therapeutic_area: null,
              },
            ],
    };
    let response = await getQuestionsByCategory(payload);

    if (response?.result?.length > 0) {
      buildOutput(response.result, categoryName, panelKey, scroll);
    }
    
    // setQuestionsToCategory(categoryName);
    return response;
  };

  // to pin/unpin the questions
  const pinUnpinQuestionClicked = async (record, actionValue) => {
    setLoading(true);

    const payload = {
      user_id: userId,
      ques_id: record.id,
      screen_name: "explore_responses",
      action: actionValue,
      analysis_id: localStorage.getItem("analysisId") || "",
      analysis_refresh_date: analysisData?.last_updated || "",
      ques_max_updated_ts: analysisData?.quesMaxUpdatedTs || "",
    };

    await setPinUnpinQuestions(payload);

    categoriesAPIData[0].total_questions =
      actionValue === "pin"
        ? categoriesAPIData[0].total_questions + 1
        : categoriesAPIData[0].total_questions - 1;

    const obj = {
      key: 1,
      category_name: categoriesAPIData[0].category_name,
      label: (
        <div style={{ display: "flex" }}>
          <span className="catName">
            {categoriesAPIData[0].category_name} (
            {categoriesAPIData[0].total_questions})
          </span>
          <div className="catDesc">
            {categoriesAPIData[0].category_description}
          </div>
        </div>
      ),
      children: "",
    };

    categoriesList[0] = obj;
    getCategoryQuestions(record.categoryName);
    getCategoryQuestions("Pinned");
    setLoading(false);
  };

  const highlightRowFun = () => {
    // setRowToHighLight(highlightRow);
    highlightRowHandler(highlightRow.panel_key);
  };

  useEffect(() => {
    onChange([]);
    getCategoriesForResponses();
  }, [filterAppliedData, searchString, edit_access_flag]);

  useEffect(() => {
    highlightRowFun();
  }, [highlightRow]);

  useEffect(() => {
    // setLoading(true);
    onChange([...activeKeys]);
  }, [deleteCardRefresh]);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const highlightRowHandler = (panelKey) => {
    // {
    //   categoryName: "",
    //   panelKey: 5,
    //   questionId: 5,
    // }
    // rowToHighLight
    // activeKeys
    if (panelKey) {
      onChange([...activeKeys, panelKey], true);
    }

    // setActiveKeys([rowToHighLight.panelKey]);
  };

  return (
    <>
      <Spin indicator={antIcon} spinning={loading}>
        <div>
          {/* {isOpen && (
            <FindingReportSettingsModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              supporting_data={supportingData}
              filterData={filterData}
              recordData={recordData}
              type={"category_responses"}
            />
          )} */}
          <Collapse
            items={categoriesList}
            activeKey={activeKeys}
            collapsible="header"
            onChange={onChange}></Collapse>
        </div>

        {respondentFilterFlag ? (
          <div>
            <ResponseDetails
              visible={true}
              responseRowRecord={responseRowRecord}
              categoryName={categoryName}
              comparatorData={filterData}
              onClose={() => responseDetailDrawer(false)}
            />
          </div>
        ) : (
          ""
        )}
      </Spin>
    </>
  );
};

export default ExploreResponseDetails;
